// importing packages
import React, { useState, useContext, useEffect } from "react";
import { Spinner, Form, Row, Col, Button } from "react-bootstrap";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";
import { constants } from "shared/library/constants";
import { getService } from "shared/library/restApi";

// importing modules
import CAForm from "./CAForm";
import Acknowledgement from "shared/components/Acknowledgment/Acknowledgment";
import "./SubmitRequest.css";

function SubmitRequest() {
  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].submitRequestPage[attr];
    }
    return languages.en.submitRequestPage[attr];
  };

  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [id, setId] = useState("");
  const [loader, setLoader] = useState(false);
  const selectedlang = useContext(booleanContext);
  const [stateSelected, setStateSelected] = useState("");
  const [nonCAStatesSelected, setNonCAStatesSelected] = useState(false);
  const [CAStateSelected, setCAStateSelected] = useState(false);
  const [configValues, setConfigValues] = useState({});
  const [relationshipType, setRelationshipType] = useState("");
  const [stateSubmitted, setStateSubmitted] = useState(false);
  const [isOptOutLimit, setIsOptOutLimit] = useState(false);

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setId(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  const handleSelectedState = (e) => {
    setStateSelected(e.target.value);
    if (e.target.value === "") {
      setNonCAStatesSelected(false);
      setCAStateSelected(false);
    }
  };

  const handleStateSubmit = (e) => {
    e.preventDefault();
    setStateSubmitted(true);
    if (stateSelected === "California") {
      setNonCAStatesSelected(false);
      setCAStateSelected(true);
    } else {
      setNonCAStatesSelected(true);
      setCAStateSelected(false);
    }
  };

  useEffect(() => {
    if (stateSubmitted) {
      // payload
      const selectedStateArr = constants.statesList.filter(
        (obj) => obj.name === stateSelected
      );
      const { code } = selectedStateArr[0];
      const inputsForPayLoad = {
        state: code,
        customer: "aflac",
      };

      const submitOptions = {
        method: `POST`,
        url: "https://7vw9yhpdwj.execute-api.us-east-1.amazonaws.com/prod/recordData",
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: inputsForPayLoad,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 200 && response.data) {
            // get the root id after successful request submit
            console.log("response", response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }

    return () => {
      setStateSubmitted(false);
    };
  }, [stateSubmitted, stateSelected]);

  const handleRelationshipType = (value) => {
    setRelationshipType(value);
  };

  const handleSelectedRequestType = (value) => {
    const bool = value === "optOut-data-processing" || value === "optOut";
    setIsOptOutLimit(bool);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    let privacyFormURL = "";
    if (relationshipType) {
      switch (relationshipType) {
        case getText("customer"):
          privacyFormURL =
            selectedlang === "es"
              ? isOptOutLimit
                ? `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_CUSTOMER_LIMIT_ESP}`
                : `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_CUSTOMER_ACC_DEL_COR_ESP}`
              : isOptOutLimit
              ? `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_CUSTOMER_LIMIT_EN}`
              : `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_CUSTOMER_ACC_DEL_COR_EN}`;
          break;
        case getText("employee"):
          privacyFormURL = isOptOutLimit
            ? `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_EMPLOYEE_LIMIT}`
            : `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_EMPLOYEE_ACC_DEL_COR}`;
          break;
        case getText("jobApplicant"):
          privacyFormURL = isOptOutLimit
            ? `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_JOB_APPLICANT_LIMIT}`
            : `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_JOB_APPLICANT_ACC_DEL_COR}`;
          break;
        case getText("salesBroker"):
          privacyFormURL = isOptOutLimit
            ? `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_SALES_AGENT_LIMIT}`
            : `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_SALES_AGENT_ACC_DEL_COR}`;
          break;
        case getText("contractor"):
          privacyFormURL = isOptOutLimit
            ? `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_CONTRACTOR_LIMIT}`
            : `${process.env.REACT_APP_AFLAC_SRR_CONFIG_DSAR_INTAKE_CONTRACTOR_ACC_DEL_COR}`;
          break;
        default:
          break;
      }

      let configOptions = {
        method: "GET",
        url: privacyFormURL,
      };
      const configData = getService(configOptions);

      configData
        .then((response) => {
          if (response.status === 200 && response.data) {
            console.log("response", response);
            const { requests, settingsId } = response.data.srrConfig;
            const actions = requests.reduce((actionsMap, request) => {
              actionsMap[request.requestType] = request;
              return actionsMap;
            }, {});

            let finalOrderedActions;

            // changing the order of actions object
            const objectTemp = {
              optOut: null,
              correction: null,
              deletion: null,
              access: null,
              "optOut-data-processing": null,
            };

            if (Object.keys(actions).length > 0) {
              finalOrderedActions = Object.assign(objectTemp, actions);
            }

            const requestTypes = Object.keys(finalOrderedActions).map(
              (request) => {
                switch (request) {
                  case "access":
                    return {
                      value: "access",
                      description:
                        "A request that Aflac disclose certain personal information that it has collected about you.",
                    };
                  case "deletion":
                    return {
                      value: "deletion",
                      description:
                        "A request that Aflac delete my personal information that is has collected and retained about you, subject to certain exceptions.",
                    };
                  case "correction":
                    return {
                      value: "correction",
                      description:
                        "A request for Aflac to correct inaccurate personal information that it maintains about you.",
                    };
                  case "optOut":
                    return {
                      value: "optOut",
                      description:
                        "A request that Aflac record your preference to not share your personal information with third parties or use your personal information for certain behavioral advertising.",
                    };
                  case "optOut-data-processing":
                    return {
                      value: "optOut-data-processing",
                      description:
                        "A request that Aflac record your preference to limit the use and disclosure of certain sensitive personal information about you to the performance of certain services.",
                    };
                  default:
                    return console.warn("Unknown Request Type", request);
                }
              }
            );

            setConfigValues({
              actions,
              requestTypes,
              settings: settingsId,
              _id: settingsId,
            });
          }
          console.log("no response");
        })
        .catch((err) => {
          throw err;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipType, selectedlang, isOptOutLimit]);

  return (
    <>
      {loader ? (
        <div className="">
          <Spinner animation="grow" variant="primary" className="spinner" />
        </div>
      ) : showAcknowledgement ? (
        <Acknowledgement id={id} request="isOptOutLimit" getText={getText} />
      ) : (
        <>
          <div className="title">{getText("pageTitle")}</div>
          <div className="cali-description">
            <p className="text-justify">{getText("pageIntro")}</p>

            <Form className="mt-3 mb-5" onSubmit={handleStateSubmit}>
              <Form.Group>
                <Row>
                  <Col xs={6} md={6} lg={4}>
                    <Form.Label>
                      <strong>{getText("stateOfResidence")}</strong>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="state"
                      className="ml-4"
                      onChange={handleSelectedState}
                      disabled={nonCAStatesSelected || CAStateSelected}
                    >
                      <option value="">{getText("selectState")}</option>
                      {constants.statesList.map((obj, index) => (
                        <option key={index} value={obj.name}>
                          {obj.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              {nonCAStatesSelected || CAStateSelected ? (
                " "
              ) : (
                <Form.Group>
                  <Row className="mt-3">
                    <Col sm={12} md={10} lg={10} className="ml-4">
                      <Button
                        type="submit"
                        className="proceed-btn"
                        disabled={!stateSelected}
                      >
                        {getText("submit")}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              )}
            </Form>

            {CAStateSelected ? (
              <>
                <section className="text-justify">
                  <p>
                    <strong>{getText("impInfo")}:</strong> {getText("infoDesc")}
                  </p>
                  <ul>
                    <li> {getText("infoText1")} </li>
                    <li>{getText("infoText2")}</li>
                    <li>
                      {" "}
                      {getText("review")}{" "}
                      <a
                        href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        target="_blank"
                        rel="noreferrer noopener"
                        title="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                      >
                        {" "}
                        {getText("privacyPolicy")}{" "}
                      </a>{" "}
                      {getText("reviewText")}{" "}
                    </li>
                  </ul>
                  <p>
                    {getText("infoText3")}
                    <br></br>
                    {getText("infoText4")}
                    <br></br>
                    {getText("infoText5")}
                  </p>
                </section>
                <CAForm
                  configValues={configValues}
                  enableLoader={handleLoader}
                  getSubmitRequestId={handleSubmitRequestId}
                  getAcknowledgement={handleAcknowledgement}
                  getText={getText}
                  getRelationshipType={handleRelationshipType}
                  selectedlang={selectedlang}
                  getSelectedRequestType={handleSelectedRequestType}
                />
              </>
            ) : (
              ""
            )}

            {nonCAStatesSelected ? (
              <section className="text-justify">
                <p>{getText("nonCAStatesText1")}</p>

                <p>{getText("nonCAStatesText2")}</p>
                <p>
                  {getText("nonCAStatesText3")}{" "}
                  <a
                    href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    target="_blank"
                    rel="noreferrer noopener"
                    title="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                  >
                    {getText("aflacPrivacyPolicy")}
                  </a>{" "}
                  {getText("moreInfo")}
                </p>
              </section>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
}

export default SubmitRequest;
