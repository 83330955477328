export const languages = {
  en: {
    privacycenter: {
      bannerTitle: "You can trust Aflac to protect your privacy",
      pageTitle: "Privacy Center",
      pageDescription:
        "At Aflac, we are committed to safeguarding the privacy of your personal information. Please explore the Privacy Center to learn more about our privacy policies, as well as your privacy rights & choices.",
      privacyPolicies: "Our Privacy Policies",
      privacyPoliciesDesc:
        "Learn more about how Aflac may collect, use and share your personal information, as described in our privacy policy.",
      seeMore: "See More",
      choices: "Your Choices",
      choicesDesc: "Take control of your personal data",
      takeControl: "Take Control",
      fraudSecurity: "Fraud & Security",
      fraudSecurityDesc:
        "Information about how to protect your personal information and report fraud.",
      contactBanner:
        "Do you have questions not answered online through our Privacy Policy or this website?",
      btnContact: "Contact Us",
      physical: "Physical Address",
      Address1: "Aflac Worldwide HeadQuarters",
      streetName: "1932 Wynton Road",
      postal: "Columbus, Georgia 31999",
      mailing: "Mailing and Email Address",
      privacyOffice: "Attn: Privacy Office",
      groupAddress1: "Aflac Group",
      groupAddress2: "1600 williams St.",
      groupAddress3: "Columbia, South Carolina 29201",
      groupAddress5: "Post Office Box 84075",
      groupAddress6: "Columbus, GA 31933",
    },
    footer: {
      aboutAflac: "About Aflac",
      careers: "Careers",
      investors: "Investors",
      duckGear: "Duck Gear",
      contactus: "Contact Us",
      policyNot: "Privacy Policy & Notifications",
      sitemap: "Sitemap",
      terms: "Terms of Use",
      brandcenter: "Brand Center",
      aflacIncorporated: "AFLAC INCORPORATED",
      viewlegal: "VIEW LEGAL",
      individual: "Individual Policies:",
      text1:
        "Coverage underwritten by American Family Life Assurance Company of Columbus. In New York, coverage underwritten by American Family Life Assurance Company of New York. Direct to Consumer individual coverage underwritten by Tier One Insurance Company.",
      text2: "Aflac Worldwide Headquarters | Columbus, GA",
      group: "Aflac Group Policies:",
      text3:
        "Continental American Insurance Company (CAIC), a proud member of the Aflac family of insurers, is a wholly-owned subsidiary of Aflac Incorporated and underwrites group coverage. CAIC is not licensed to solicit business in New York, Guam, Puerto Rico, or the Virgin Islands. For groups sitused in California, group coverage is underwritten by Continental American Life Insurance Company. For groups sitused in New York, coverage is underwritten by American Family Life Assurance Company of New York.",
      text4: "Aflac Group | Columbia, SC",
      text5:
        '"Aflac" may include American Family Life Assurance Company of Columbus, American Family Life Assurance Company of New York, Continental American Insurance Company (marketed as "Aflac Group"), Tier One Insurance Company, and any other affiliated companies (collectively, "Aflac"), as applicable to the entity from whom you receive insurance services.',
    },
    header: {
      privacyCenter: "Privacy Center",
      policies: "Our Privacy Policies",
      rights: "Your Rights & Choices",
      fraud: "Fraud & Security",
      affMarketing: "Affiliate Marketing",
      exerciseRights: "Exercise Your Rights",
      privacyPolicy: "Privacy Policy",
      privacyNotice: "Privacy Notice",
      privacyNotif: "Privacy Notification",
      careerAgreement: "Career Privacy Agreement",
      terms: "Terms of Use",
      cookies: "Cookies and Tracking Technology Policy",
      highContrastMode: "High Contrast Mode",
    },
    privacyPolicies: {
      bannerTitle: "At Aflac, we value your privacy",
      pageTitle: "Our Privacy Policies",
      pageDescription:
        "We understand the importance of being transparent as to how we collect and use your personal data. As part of this transparency, we provide you with access to our privacy policies for better understanding of why data collection and protection is important to us.",
      privacyPolicy: "Privacy Policy",
      privacyPolicyDesc:
        "Understand the collection, use, disclosure, and retention of your personal information.",
      privacyNotice: "Privacy Notice",
      privacyNoticeDesc:
        "Notices related to information we share pursuant to state and federal laws.",
      privacyNotification: "Privacy Notification",
      privacyNotificationDesc:
        "Understand your rights under the NY Domestic Violence Insurance Regulation 168.",
      careersAgreement: "Careers Privacy Agreement",
      careersAgreementDesc:
        "How Aflac uses personal information you provide when applying for employment.",
      terms: "Terms of Use",
      termsDesc:
        "Understand your respective legal rights and obligations while using our services.",
      cookiesTracking: "Cookies and Tracking Policy",
      cookiesTrackingDesc:
        "How Aflac and its advertising partners use these technologies and how you can control them.",
    },
    fraudSecurity: {
      bannerTitle: "Keep your information secure",
      pageTitle: "Fraud and Security",
      quote1: '"98% of cyber attacks rely on social engineering."',
      reviewerName1: "Purplesec",
      quote2:
        '"Phishing attacks account for more than 80% of reported security incidents."',
      reviewerName2: "CSO Online",
      quote3: '"There is a cyber attack every 39 seconds."',
      reviewerName3: "Fintechnews.org",
      pageDescription:
        "Aflac is fully committed to the security and protection of our customer’s data. We encourage you to learn how to better protect yourself by recognizing, preventing and reporting fraudulent activity to ensure that your information and identity remain safe.",
      bestPractices: "Best Practices to Protect Yourself Online",
      commonScams: "Common Scams and How to Avoid Them",
      fraud: "How to Report Fraud",
      addResources: "Additional Resources",
      protectInfo: "How We Protect Your Information",
      statement1:
        "We maintain physical, electronic and procedural safeguards that comply with applicable legal standards to secure such information from unauthorized access and use, accidental or unlawful alteration and destruction, and other unlawful or unauthorized forms of Processing.",
      statement2:
        "We hold our employees accountable for complying with relevant policies, procedures, rules and regulations concerning the privacy and confidentiality of information.",
      statement3:
        "Our Global Security team continuously evaluates and enhances how we protect our information using industry best practices.",
      firstFSIntro:
        "Over the past few years, there has been an uptick in cyber attacks across the world. Here are some ways you can stay safe online:",
      listItem1:
        "Secure your computing devices. Install anti-virus software, firewalls, email filters and keep these up-to-date. Set your operating system to automatically update.",
      listItem2:
        "Use a password manager tool, or at least strong passwords. Never share or reuse the same password for multiple accounts.",
      listItem3:
        "Use Multi-Factor Authentication whenever possible. This adds a second layer of security to your important accounts by requiring an additional step to verify your identity, such as receiving a code through text message or email.",
      listItem4:
        "Protect your Personal Identifiable Information (PII). Never give your information over the phone or in an email. Legitimate companies will never ask for it.",
      listItem5:
        "Secure your mobile device by using passwords and keeping it locked when not in use. Only connect to secure wi-fi, and be cautious of downloads.",
      listItem6:
        "Backup your data regularly to protect it in the event of a system crash or failure. This will also ensure you have access to your information in the event your mobile device is stolen.",
      listItem7:
        "Be cautious of using public wi-fi. Never access your bank accounts, personal information, or go shopping while using public wi-fi. Turn off automatic connectivity, especially when traveling in unfamiliar places.",
      listItem8:
        "Protect your Personal Identifiable Information (PII). Never give your information over the phone or in an email. Legitimate companies will never ask for it.",
      secondFSIntro:
        "Social engineering is the art of manipulating people so they give up confidential information. The type of information can vary, but criminals are usually trying to trick you into giving them personally identifiable data, your passwords or bank information. Exposing this information can lead to identity theft.",
      secondFSMoreIntro:
        "Below here are the most common methods involving social engineering techniques.",
      thirdFSIntro:
        "Aflac is an organization with strong values of responsibility and integrity. If you suspect fraudulent activity you are encouraged to visit",
      reportingFraud: "Reporting Fraud",
      thirdFSMoreIntro:
        "on the Aflac home page for details on how to submit a report.",
      fourthFSIntro:
        "We recommend the following sites for additional information about how to protect yourself against identity theft and respond if it happens.",
      FTC: "Federal Trade Commission (FTC)",
      PIS: "Privacy, Identity & Online Security",
      safeSecure: "Tips to Help You Stay Safe and Secure Online",
      NCSA: "National Cyber Security Alliance (NCSA)",
      staySafe: "Stay Safe Online",
      FBI: "Federal Bureau of Investigation (FBI)",
      cyberCrime: "Cyber Crime",
      CTIA: "Cellular Telecommunications Industry Association (CTIA)",
      protectData: "Protecting Your Data",
      phishing: "Phishing",
      phishingIntro:
        "Phishing attacks are emails designed to deceive and trick recipients into taking an action such as clicking a malicious link, or opening an attachment with a virus.",
      themesText: "Common themes include",
      phishingText1:
        "Presenting a problem that requires you to “verify” your information.",
      phishingText2: "Posing as a boss, coworker, or trusted company.",
      phishingText3: "Urgently asking you for help.",
      phishingText4: "Ask you to donate to a charity or fundraiser.",
      phishingText5: "Notifying you that you are a “winner”.",
      protectYourself: "Protect Yourself",
      phishingText6:
        "Slow Down- Criminals want you to act first and think later. If the message conveys a sense of urgency or uses high-pressure sales tactics, be skeptical.",
      phishingText7:
        "Never provide personally identifiable information. Delete any request for financial information or passwords. If you get asked to reply to a message with personal information, it’s a scam.",
      phishingText8:
        "Set your spam filters to high. Every email program has spam filters. To find yours, look at your settings options, and set these to high–just remember to check your spam folder periodically to see if legitimate email has been accidentally trapped there. You can also search for a step-by-step guide to setting your spam filters by searching on the name of your email provider plus the phrase ’spam filters’.",
      phishingText9:
        "Protect your Personal Identifiable Information (PII). Never give your information over the phone or in an email. Legitimate companies will never ask for it.",
      vishing: "Vishing",
      vishingIntro:
        "Vishing is a form of attack that attempts to trick people into giving up sensitive personal information over the phone. In most cases, the attacker strategically manipulates human emotions, such as fear, sympathy, and greed in order to accomplish their goals.",
      vishingText1:
        "Government impersonations, including the IRS, Medicare, the Social Security Administration, or local law enforcement.",
      vishingText2:
        "Telemarketing fraud - a too good to be true situation, where you’ve won a contest, or you’re being offered a free vacation.",
      vishingText3:
        "Tech support fraud - a call from tech support, pretending to answer your questions or trying to get you to verify information.",
      vishingText4:
        "Bank account scams - a call from someone pretending to be from your bank following up on potentially fraudulent charges.",
      vishingText5:
        "One thing that every vishing scam has in common is an attempt to create a false sense of urgency, making you think you're in trouble or about to miss an opportunity and need to act right now. It never hurts to take a moment to pause, write down information about the caller without offering any of your own, and then call back after doing research.",
      vishingText6:
        "Be suspicious of a call claiming to be from a government agency or asking for money or information. When in doubt, hang up, independently seek out the real number for the agency, and call them to find out if they're trying to reach you.",
      vishingText7:
        "Never pay for anything with a gift card or a wire transfer. That's a strong sign of a scam.",
      vishingText8:
        "Legitimate callers will be happy to work with you. Illegitimate callers may quickly move on to finding an easier target.",
      smishing: "Smishing",
      smishingIntro:
        "Smishing is a cyberattack that uses misleading text messages. The goal is to trick the recipient into believing that a message has arrived from a trusted person or organization, and then convincing them to take action that gives the attacker exploitable information (like bank account login credentials, for example) or access to the mobile device.",
      smishingText1:
        "Attempts to trick you into sharing credentials. Smishers may try to convince you into giving up a username / password combo or other confidential info that they can use to log into one of your online accounts. Banking schemes and Amazon schemes are very common.",
      smishingText2:
        "Attempts to trick you into downloading malware. Smishers send vague messages with links or push recipients to download apps. Like phishing, these links or downloads could have malicious intents, such as stealing credit card information or giving access to your mobile device.",
      smishingText3:
        "Be wary of texts using unnatural or ungrammatical language.",
      smishingText4: "Offers that seem too good to be true usually are.",
      smishingText5:
        "Don't click embedded links or download apps directly from a text message.",
      smishingText6:
        "The IRS and Social Security Administration don't communicate via text.",
      recoverAttack: "How to recover from a social engineering attack",
      recoverSummary:
        "Recovering from a social engineering attack depends on the nature of the attack and what was compromised. Ensure your anti-virus is up to date and change your passwords. If you mistakenly gave the attacker any personal information, contact any impacted institution for guidance on how to protect your data. If you suspect your credit information has been compromised, immediately contact the credit bureaus to freeze your credit or request their guidance on recovery steps.",
    },
    rights: {
      bannerTitle: "Exercise Your Rights",
      pageTitle: "Privacy Rights Request",
      introTitle: "California Consumer Privacy Act (CCPA)",
      introDesc:
        "The CCPA gives California residents certain privacy rights with respect to some of the personal information we collect. These rights are:",
      listText1: "The right to notice of the personal information we collect.",
      listText2:
        "The right to know the categories, sources and specific pieces of personal information we have collected about you in the past 12 months, including our purpose for collecting the information and the categories of third parties with whom we share that personal information, subject to certain exceptions.",
      listText3:
        "The right to delete some or all of the personal information we collect, subject to certain exceptions.",
      listText4:
        "The right to opt-out of our sale of your personal information, if we sell your personal information.",
      rightsTitle: "You Can Exercise Your Rights Here",
      submitRequestTitle: "Submit a Personal Data Request",
      submitRequestBtn: "Submit a Request",
      agentRequestText:
        "If you are submitting this request on behalf of someone who is a California state resident, you must submit the request via mail. Please download CCPA mail instructions",
      here: "here",
      checkStatusTitle: "Already submitted a request?",
      checkStatusBtn: "Check Status",
      requestByCall:
        "You are also welcome to submit a personal data request by calling our toll-free number 1-855-768-9730.",
      requestByMail: "And, you may submit a request via mail.",
      downloadinstr: "Download Instructions",
    },
    submitRequestStart: {
      introText:
        "The California Consumer Privacy Act (CCPA) is California legislation that allows California residents to access or delete personal data that a business collects about them. Because the CCPA only provides these choices to California residents, Aflac will only fulfill requests submitted by California residents.",
    },
    optoutRequestStart: {
      introText1:
        "The California Consumer Privacy Act (CCPA) is California legislation that allows California residents to opt-out of the selling of their personal information. Because the CCPA only provides this choice to California residents, Aflac will only fulfill requests submitted by California residents.",
      introText2:
        "As a reminder, Aflac does not sell personal information about current or former customers to any third parties. Although these are not our practices, we are providing our customers with the opportunity to advise us of their preference and formally record it.",
      introText3:
        "Please select the option that best describes your California Residency status.",
    },
    starterPage: {
      pageTitle: "Start the Process",
      californiaResident: "I'm a California Resident",
      nonCaliforniaResident: "I'm not a California Resident",
      intro: "Before starting the process, please make sure you have:",
      header1: "A valid email address you can access.",
      description1: `You’ll be asked to verify your email address after sending the request. If you’re a current or former customer of Aflac or Aflac affiliates, this email must be associated with your account. If you have multiple email addresses associated with an account, please submit a separate request for each email.`,
      header2:
        "A digital copy or photo of a valid U.S. driver’s license or passport.",
      description2: `Note: We only support verification using U.S. driver’s license and passport at this time. Please do not submit any other form of identification, such as Military ID.`,
      description3:
        "If you would like to learn more about how Aflac may collect, use, and share your personal information, please visit our",
      privacyPolicy: "Privacy Policy",
      requestByCall:
        "You are also welcome to submit a personal data request by calling our toll-free number 1-855-768-9730.",
      requestByMail: "And, you may submit a request via mail.",
      downloadinstr: "Download Instructions",
    },
    rejectionPage: {
      pageTitle:
        "The CCPA extends privacy rights to California residents only.",
      introText:
        "You have indicated that you are not a California resident. As a financial institution and insurance company, please be assured that all customer data collected, processed, stored or disclosed by Aflac is subject to and protected by the Gramm-Leach-Bliley Act (GLBA), the Health Insurance Portability and Accountability Act (HIPAA), and other applicable state privacy and data security laws. At Aflac, we value your privacy and consider the protection of your personal information critically important.",
      policyText:
        "If you would like to learn more about how Aflac may collect, use, and share your personal information, please visit our",
      privacyPolicy: "Privacy Policy",
    },
    submitRequestPage: {
      pageTitle: "Submit Request",
      pageIntro:
        "You can make certain requests regarding our collection, use, and sharing of your personal information. The requests available to you may vary by state. We will fulfill requests as determined by your state of residence.",
      stateOfResidence: "State of Residence",
      selectState: "Select State",
      submit: "Submit",
      nonCAStatesText1:
        "Thank you for your request. The privacy rights that are available through this process are not available in your state of residence.",
      nonCAStatesText2:
        "As a financial institution and insurance company, personal information that we may collect, process, store or disclose is subject to various federal and state privacy and data protections laws, including the Gramm-Leach-Bliley Act (GLBA) and the Health Insurance Portability and Accountability Act (HIPAA).",
      nonCAStatesText3:
        "At Aflac, we value your privacy and consider the protection of your personal information critically important. If you would like to learn more about how Aflac may collect, use and share your personal information, please see the",
      aflacPrivacyPolicy: "Aflac Privacy Policy",
      moreInfo: "for more information.",
      virginiaAct: "Virginia Data Protection Act (VCDPA)",
      coloradoAct: "Colorado Privacy Act (CPA)",
      utahAct: "Utah Consumer Privacy Act (UCPA)",
      connecticutAct: "Connecticut Data Privacy Act (CDPA)",
      nonComplyStatesText:
        "As a financial institution and insurance company, Aflac is exempt from the",
      impInfo: "Important Information",
      infoDesc:
        "Please note that there may be instances where access to your personal information, or deletion of your personal information, may be restricted by federal or state law. If we are prohibited from fulfilling your privacy rights, in whole or in part, we will advise you of the specific reasons, barring any legal or regulatory limitations.",
      infoText1:
        "Your privacy choices may not be properly applied if you enter incorrect information.",
      infoText2:
        "The personal information that you provide will be used for verification purposes ONLY.",
      review: "Please review our",
      privacyPolicy: "Privacy Policy",
      reviewText:
        "for complete details about how Aflac, Inc. manages consumer information.",
      infoText3: "Before starting the process, please make sure you have:",
      infoText4:
        "(a) A valid email address you can access. You'll be asked to verify your email address after sending the request.",
      infoText5:
        "(b) A digital copy or photo of a valid U.S. driver's license or passport.",
      requestByMail:
        "If you are submitting this request on behalf of someone else, you must submit the request via mail. Please download CCPA mail-in instructions",
      here: "here",
      email: "Enter additional details for your request.",
      emailPlaceholder: "Email Address *",
      emailText: "We'll use this email address to reply to your request.",
      relationshipToAflac:
        "What is your current or former relationship to Aflac?",
      customer:
        "Customer (Policyholder, Certificate Holder, Spouse or Dependent)",
      employee: "Employee",
      jobApplicant: "Job Applicant",
      salesBroker: "Sales Agent or Broker",
      contractor: "Contractor",
      typeOfRequest: "What type of request would you like to make?",
      access: "Access",
      accessDescription:
        "A request that Aflac disclose certain personal information that it has collected about you.",
      delete: "Delete",
      deleteDescription:
        "A request that Aflac delete personal information that it has collected and retained about you, subject to certain exceptions.",
      optOut: "Opt Out of Sell or Share",
      optOutDescription:
        "A request that Aflac record your preference to not share your personal information with third parties or use your personal information for certain behavioral advertising.",
      correction: "Correction",
      correctionDescription:
        "A request for Aflac to correct inaccurate personal information that it maintains about you.",
      optOutDataProcessing: "Limit Sensitive Personal Information",
      optOutDataProcessingDescription:
        "A request that Aflac record your preference to limit the use and disclosure of certain sensitive personal information about you to the performance of certain services.",
      specifyUpdateReq: "Please specify what needs to be updated / corrected.",
      specifyUpdateReqPlaceholder:
        "Please do not provide any sensitive personal information through this form.",
      eNumber:
        "Please provide your E-Number or the last 4 digits of your Social Security Number.",
      ssnDigits: "Last 4 Digits of SSN",
      writingNumberLabel:
        "Please provide your Writing Number or the last 4 digits of your Social Security Number or Tax ID Number.",
      writingNumber: "Writing Number",
      ssntnDigits: "Last 4 Digits of SSN / TIN",
      vNumber: "Please provide your V-Number.",
      recentStatus:
        "Select the company through which you have applied for or been issued coverage.",
      personType:
        "Are you the primary person who applied for coverage or the Spouse / Dependent?",
      requestType: "What type of request would you like to make?",
      aflacIndividual: "Aflac Individual",
      aflacGroup: "Aflac Group",
      notSure: "Not Sure",
      both: "Both",
      neither1: "Neither",
      primary: "Primary",
      spouse: "Spouse",
      dependent: "Dependent",
      neither2: "Neither",
      spouseDepPlaceholderFN: "Spouse / Dependent First Name *",
      spouseDepPlaceholderLN: "Spouse / Dependent Last Name *",
      accessInfo: "Access my personal information",
      deleteInfo: "Delete my personal information",
      enterpriInsuredInfo:
        "Please enter the required information for the Primary Insured.",
      firstName: "First Name *",
      lastName: "Last Name *",
      address1: "Address Line 1 *",
      address2: "Address Line 2",
      city: "City *",
      state: "State *",
      zipCode: "Postal Zip Code *",
      country: "Country *",
      dob: "Date of Birth *",
      required: "* fields are required",
      submitBtn: "Submit",
      cancelBtn: "Cancel",
      ackTitle: "Acknowledgment",
      cardTitle: "Check Your Inbox",
      cardText1:
        "We've sent you a link to continue with your request. Please proceed to your inbox to look for an email from",
      cardText1OptOuts:
        "Please proceed to your inbox to look for an email from",
      cardText2:
        "If you do not receive the email in the next few hours, please check your SPAM filters or resubmit your request.",
      requestID: "Your Request ID:",
      save: "Please save this for your records.",
      cardText3:
        "The email will prompt you to confirm your request. You may also need to verify your identity by uploading a copy of your driver’s license or passport along with current photo of yourself. You must submit your government ID and current photo within 60 minutes from the receipt of the email, or you will have to resubmit your request.",
    },
    optoutRequestPage: {
      pageTitle: "Opt-Out Request",
      pageIntro:
        "On this page, you may elect to exercise your opt-out right as a California resident.",
      impInfo: "Important Information",
      infoDesc:
        "If we are unable to process your opt-out request we will advise you of the specific reason, barring any legal or regulatory limitations.",
      infoText1:
        "Your privacy choices may not be properly applied if you enter incorrect information.",
      infoText2:
        "The personal information that you provide will be used for verification purposes ONLY.",
      review: "Please review our",
      privacyPolicy: "Privacy Policy",
      reviewText:
        "for complete details about how Aflac, Inc. manages consumer information.",
      requestByMail:
        "If you are submitting this request on behalf of someone else, you must submit the request via mail. Please download CCPA mail-in instructions",
      email: "Email *",
      emailPlaceholder: "Enter your Email Address *",
      emailText: "We'll use this email address to reply to your request.",
      enterpriInsuredInfo:
        "Please enter the required information for the Primary Insured.",
      firstName: "First Name *",
      lastName: "Last Name *",
      address1: "Address Line 1 *",
      address2: "Address Line 2",
      city: "City *",
      state: "State *",
      zipCode: "Postal Zip Code *",
      country: "Country *",
      dob: "Date Of Birth *",
      required: "* fields are required",
      submitBtn: "Submit",
      cancelBtn: "Cancel",
      ackTitle: "Acknowledgment",
      cardTitle: "Check Your Inbox",
      cardText1:
        "We’ve sent you a link to continue with your request. Please proceed to your inbox to look for an email from",
      cardText2:
        "If you do not receive the email in the next few hours, please check your SPAM filters or resubmit your request.",
      requestID: "Your Request ID:",
      save: "Please save this for your records.",
      cardText3: "The email will prompt you to confirm your request.",
    },
    pdfPage: {
      back: "Back",
      download: "Download PDF",
      instructions: "Aflac Mail-in Instructions",
      header1:
        "The privacy rights that are available through this process are only available to California state residents.",
      header2: "To submit and receive your request through the mail",
      listText1:
        "Proof of Identity (copy of the front and back of an Acceptable Proof of Identity)",
      listItem1:
        "Acceptable Proof of Identity is State Issued ID / Driver’s License or Passport or Passport Card.",
      listText2:
        "The type of request being submitted and provide contact information including name, phone number, address, and whether you are a California resident or not.",
      listText3:
        "Allow up to 45 days for Aflac to process the request and this may be extended by an additional 45 days if more time is needed. If an extension occurs, Aflac will inform you within the initial 45-day window.",
      listText4: "Mail the request to:",
      listItem2:
        "For Aflac: Aflac, Attn: Privacy Office, 1932 Wynnton Road, Columbus, GA 31999.",
      listItem3:
        "For Aflac Group / CAIC: Aflac Group, Attn: Privacy Office, PO Box 427, Columbia, SC 29202.",
      header3:
        "If submitting this request on behalf of someone else, in addition to the above, please also include:",
      listText5:
        "Power of Attorney or another official document that authorizes you to act on behalf of the Data Subject.",
      listText6:
        "Proof of Identity of the third-party individual making the request (copy of the front and back of an Acceptable Proof of Identify).",
      listItem4:
        "Acceptable Proof of Identity is State Issued ID / Driver’s License or Passport or Passport Card.",
      listText7:
        "Copy of proof of registration with the Secretary of State (if you represent a business entity).",
      listText8:
        "Contact information, including your name, phone number, and address to send communications to.",
    },
  },

  es: {
    privacycenter: {
      bannerTitle: "Puede confiar en que Aflac protegerá su privacidad",
      pageTitle: "Centro de Privacidad",
      pageDescription:
        "En Aflac, nos comprometemos a proteger la privacidad de su información personal. Por favor explore el Centro de Privacidad para obtener más información sobre nuestras políticas de privacidad, así como sobre sus derechos y opciones de privacidad.",
      privacyPolicies: "Nuestras Políticas de Privacidad",
      privacyPoliciesDesc:
        "Obtenga más información sobre cómo Aflac puede recopilar, usar y compartir su información personal, según se describe en nuestra Política de Privacidad.",
      seeMore: "MÁS INFORMACIÓN",
      choices: "Sus Opciones",
      choicesDesc: "Asuma el control de sus datos personales",
      takeControl: "ASUMA EL CONTROL",
      fraudSecurity: "Fraude y Seguridad",
      fraudSecurityDesc:
        "Información sobre cómo proteger su información personal y denunciar el fraude.",
      contactBanner:
        "¿Tiene alguna pregunta que no se haya respondido en línea a través de nuestra Política de Privacidad o este sitio web?",
      btnContact: "Contáctenos",
      physical: "Dirección Física",
      Address1: "Sede Mundial de Aflac",
      streetName: "1932 Wynton Road",
      postal: "Columbus, Georgia 31999",
      mailing: "Dirección de correo postal y correo electrónico",
      privacyOffice: "Attn: Privacy Office",
      groupAddress1: "Aflac Group",
      groupAddress2: "1600 williams St.",
      groupAddress3: "Columbia, South Carolina 29201",
      groupAddress5: "Post Office Box 84075",
      groupAddress6: "Columbus, GA 31933",
    },
    footer: {
      aboutAflac: "Sobre Aflac",
      careers: "Careers",
      investors: "Investors",
      duckGear: "Duck Gear",
      contactus: "Contáctanos",
      policyNot: "Privacy Policy & Notifications",
      sitemap: "Mapa del Sitio",
      terms: "Terms of Use",
      brandcenter: "Brand Center",
      aflacIncorporated: "AFLAC INCORPORADO",
      viewlegal: "Avisos Legales",
      individual: "Pólizas Individuales:",
      text1:
        "La cobertura está suscrita por American Family Life Assurance Company of Columbus. En New York, la cobertura es suscrita por American Family Life Assurance Company of New York. La cobertura individual Directa al Consumidor está suscrita por Tier One Insurance Company.",
      text2: "Sede Mundial de Aflac | Columbus, GA",
      group: "Pólizas Colectivas de Aflac Group:",
      text3:
        "Continental American Insurance Company (CAIC), un orgulloso miembro de la familia de compañías de seguros Aflac, es una subsidiaria de propiedad absoluta de Aflac Incorporated que suscribe cobertura colectiva. CAIC no está autorizada para ofrecer sus servicios en New York, Guam, Puerto Rico ni en Virgin Islands. Para los grupos ubicados en California, la cobertura de seguro colectivo está suscrita por Continental American Life Insurance Company. Para grupos ubicados en New York, la cobertura está suscrita por American Family Life Assurance Company of New York.",
      text4: "Aflac Group | Columbia, SC",
      text5:
        '"Aflac" puede incluir American Family Life Assurance Company of Columbus, American Family Life Assurance Company of New York, Continental American Insurance Company (comercializado como "Aflac Group"), Tier One Insurance Company, y cualquier otra compañía afiliada (colectivamente, "Aflac"), según corresponda a la entidad de quien usted recibe los servicios del seguro.',
    },
    header: {
      privacyCenter: "Centro de Privacidad",
      policies: "Nuestras Políticas de Privacidad",
      rights: "Sus Derechos y Opciones",
      fraud: "Fraude y Seguridad",
      affMarketing: "Marketing de Filiales",
      exerciseRights: "Ejerza Sus Derechos",
      privacyPolicy: "Política de Privacidad",
      privacyNotice: "Aviso de Privacidad",
      privacyNotif: "Notificación de Privacidad",
      careerAgreement: "Acuerdo de Privacidad Profesional",
      terms: "Términos de Uso",
      cookies: "Práctica de Cookies y Tecnología de Seguimiento",
      highContrastMode: "Modo de alto contraste",
    },
    privacyPolicies: {
      bannerTitle: "En Aflac, valoramos su privacidad",
      pageTitle: "Nuestras Políticas de Privacidad",
      pageDescription:
        "Comprendemos la importancia de ser transparentes en cuanto a la forma en que recopilamos y utilizamos sus datos personales. Como parte de esta transparencia, le brindamos acceso a nuestras políticas de privacidad para que comprenda mejor por qué la recopilación y protección de datos es importante para nosotros.",
      privacyPolicy: "Política de Privacidad",
      privacyPolicyDesc:
        "Comprenda cómo se recopila, usa, divulga y conserva su información personal.",
      privacyNotice: "Aviso de Privacidad",
      privacyNoticeDesc:
        "Avisos relacionados con la información que compartimos conforme a las leyes estatales y federales.",
      privacyNotification: "Notificación de Privacidad",
      privacyNotificationDesc:
        "Conozca sus derechos bajo la Reglamentación 168 de protección contra la violencia doméstica de New York.",
      careersAgreement: "Acuerdo de Privacidad Profesional",
      careersAgreementDesc:
        "Cómo Aflac utiliza la información personal que usted proporciona al solicitar empleo.",
      terms: "Términos de Uso",
      termsDesc:
        "Conozca sus respectivos derechos y obligaciones legales al utilizar nuestros servicios.",
      cookiesTracking: "Política de Cookies y Seguimiento",
      cookiesTrackingDesc:
        "Cómo Aflac y sus socios publicitarios utilizan estas tecnologías y cómo puede usted controlarlas.",
    },
    fraudSecurity: {
      bannerTitle: "Mantenga su información protegida",
      pageTitle: "Fraude y Seguridad",
      quote1: '"El 98% de los ciberataques se basan en la ingeniería social."',
      reviewerName1: "Purplesec",
      quote2:
        '"Los ataques de phishing representan más del 80% de los incidentes de seguridad informados."',
      reviewerName2: "CSO en Línea",
      quote3: '"Hay un ciberataque cada 39 segundos."',
      reviewerName3: "Fintechnews.org",
      pageDescription:
        "Aflac está plenamente comprometida con la seguridad y la protección de los datos de nuestros clientes. Le recomendamos que aprenda a protegerse mejor reconociendo, previniendo y denunciando las actividades fraudulentas a fin de garantizar la seguridad de su información e identidad.",
      bestPractices: "Mejores Prácticas para Protegerse en Línea",
      commonScams: "Estafas Comunes y Cómo Evitarlas",
      fraud: "Cómo Denunciar Fraudes",
      addResources: "Recursos Adicionales",
      protectInfo: "Cómo Protegemos Su Información",
      statement1:
        "Contamos con protecciones físicas, electrónicas y de procedimiento que cumplen con las normas legales vigentes para proteger dicha información del acceso y uso no autorizados, de la alteración y la destrucción accidental o ilegal, y de otras formas de tratamiento ilegales o no autorizadas.",
      statement2:
        "Hacemos responsables a nuestros empleados del cumplimiento de las políticas, los procedimientos, las normas y las reglamentaciones pertinentes relativos a la privacidad y la confidencialidad de la información.",
      statement3:
        "Nuestro equipo de Seguridad Global evalúa y mejora continuamente la forma en que protegemos nuestra información utilizando las mejores prácticas del sector.",
      firstFSIntro:
        "En los últimos años, se ha producido un aumento en los ataques cibernéticos en todo el mundo. Las siguientes son algunas de las formas en las que puede mantenerse seguro en linea:",
      listItem1:
        "Proteja sus dispositivos informáticos. Instale programas antivirus, cortafuegos y filtros de correo electrónico y manténgalos actualizados. Configure su sistema operativo para que se actualice automáticamente.",
      listItem2:
        "Utilice una herramienta de administración de contraseñas o, al menos, contraseñas seguras. Nunca comparta ni reutilice la misma contraseña para varias cuentas.",
      listItem3:
        "Utilice un sistema de Autenticación de Múltiples Factores siempre que sea posible. Esto incorpora una segunda capa de seguridad a sus cuentas importantes, ya que requiere un paso adicional para verificar su identidad, como recibir un código a través de un mensaje de texto o un correo electrónico.",
      listItem4:
        "Proteja su Información de Identificación Personal (PII). Nunca suministre sus datos por teléfono ni por correo electrónico. Las empresas legítimas nunca los pedirán.",
      listItem5:
        "Proteja su dispositivo móvil utilizando contraseñas y manteniéndolo bloqueado cuando no lo utilice. Conéctese únicamente a redes wifi seguras y tenga cuidado con las descargas.",
      listItem6:
        "Realice una copia de seguridad de sus datos con regularidad para protegerlos en caso de interrupción o falla del sistema. Esto también le garantizará el acceso a su información en caso de que le roben el dispositivo móvil.",
      listItem7:
        "Tenga cuidado al utilizar una red wifi pública. Nunca acceda a sus cuentas bancarias o a su información personal ni haga compras mientras utiliza una red wifi pública. Desactive la conectividad automática, especialmente cuando viaja a lugares desconocidos.",
      listItem8:
        "Proteja su Información de Identificación Personal (PII). Nunca suministre sus datos por teléfono ni por correo electrónico. Las empresas legítimas nunca los pedirán.",
      secondFSIntro:
        "La ingeniería social es el arte de manipular a las personas para que entreguen información confidencial. El tipo de información puede variar, pero los delincuentes suelen intentar engañarlo para que les proporcione datos de identificación personal, sus contraseñas o su información bancaria. Exponer esta información puede dar lugar a un robo de identidad.",
      secondFSMoreIntro:
        "A continuación, se presentan los métodos más comunes que incluyen técnicas de ingeniería social.",
      thirdFSIntro:
        "Aflac es una organización con sólidos valores de responsabilidad e integridad. Si sospecha de una actividad fraudulenta, le recomendamos que visite ",
      reportingFraud: "Denuncias de Fraude",
      thirdFSMoreIntro:
        "en la página principal de Aflac, donde encontrará información sobre cómo presentar una denuncia.",
      fourthFSIntro:
        "Recomendamos los siguientes sitios para obtener información adicional sobre cómo protegerse contra el robo de identidad y cómo responder en caso de que ocurra",
      FTC: "Comisión Federal de Comercio (FTC)",
      PIS: "Privacidad, Identidad y Seguridad en Línea",
      safeSecure: "Consejos para Ayudarlo a Mantenerse Seguro en Linea",
      NCSA: "Alianza Nacional de Ciberseguridad (NCSA)",
      staySafe: "Manténgase seguro en Linea",
      FBI: "Oficina Federal de Investigación (FBI)",
      cyberCrime: "Ciberdelincuencia",
      CTIA: "Asociación de la Industria de las Telecomunicaciones Celulares (CTIA)",
      protectData: "Protección de Sus Datos",
      phishing: "Phishing",
      phishingIntro:
        "Los ataques de phishing consisten en correos electrónicos diseñados para engañar a los destinatarios y lograr que realicen una acción como hacer clic en un enlace malintencionado o abrir un archivo adjunto con un virus.",
      themesText: "Los temas frecuentes incluyen los siguientes",
      phishingText1:
        'Presentar un problema que requiera "verificar" su información.',
      phishingText2:
        "Suplantar la identidad de un jefe, un compañero de trabajo o una compañía de confianza.",
      phishingText3: "Solicitar su ayuda con urgencia.",
      phishingText4:
        "Pedirle que realice una donación a una organización benéfica o de recaudación de fondos.",
      phishingText5: 'Notificarle que "ganó" un premio.',
      protectYourself: "Protéjase",
      phishingText6:
        "No se apresure; los delincuentes quieren que usted actúe primero y piense después. Si el mensaje transmite una sensación de urgencia o utiliza tácticas de venta de alta presión, sea escéptico.",
      phishingText7:
        "Nunca proporcione información de identificación personal. Elimine toda solicitud de información financiera o de contraseñas. Si le piden que responda a un mensaje con información personal, se trata de una estafa.",
      phishingText8:
        'Configure los filtros de correo no deseado en un nivel alto. Todos los programas de correo electrónico poseen filtros de correo no deseado. Para encontrar el suyo, busque en sus opciones de configuración y establézcalo en un nivel alto, pero recuerde revisar periódicamente la carpeta de correo no deseado para verificar si algún mensaje de correo legítimo ha quedado atrapado allí por accidente. También puede buscar una guía paso a paso para configurar los filtros de correo no deseado buscando el nombre de su proveedor de correo electrónico más la frase "filtros de correo no deseado".',
      phishingText9:
        "Proteja su Información de Identificación Personal (PII). Nunca suministre sus datos por teléfono ni por correo electrónico. Las empresas legítimas nunca los pedirán.",
      vishing: "Vishing",
      vishingIntro:
        "El vishing es una forma de ataque que intenta engañar a las personas para que proporcionen información personal confidencial por teléfono. En la mayoría de los casos, el atacante manipula estratégicamente las emociones humanas, como el miedo, la empatía y la codicia, para lograr sus objetivos.",
      vishingText1:
        "Suplantaciones del gobierno, incluidos el IRS, Medicare, la Administración del Seguro Social o las fuerzas del orden público.",
      vishingText2:
        "Fraude de telemercadeo: una situación demasiado buena para ser cierta, en la que ha ganado un concurso o le ofrecen unas vacaciones gratis.",
      vishingText3:
        "Fraude del servicio técnico: una llamada del servicio técnico en la que alguien finge responder a sus preguntas o intenta que usted verifique información.",
      vishingText4:
        "Estafas en cuentas bancarias: una llamada de alguien que finge pertenecer a su banco para realizar un seguimiento de cargos posiblemente fraudulentos.",
      vishingText5:
        "Una cosa que todas las estafas de vishing tienen en común es el intento de crear una falsa sensación de urgencia, haciéndole creer que está en problemas o que está a punto de perder una oportunidad y que debe actuar de inmediato. Nunca está de más tomarse un momento de reflexión, anotar información sobre la persona que llama sin ofrecer ningún dato propio y luego volver a llamar después de investigar.",
      vishingText6:
        "Desconfíe de una llamada que manifieste ser de una agencia gubernamental o que pida dinero o información. En caso de duda, cuelgue, busque de forma independiente el número real de la agencia y llámelos para saber si están intentando de comunicarse con usted.",
      vishingText7:
        "Nunca pague nada con una tarjeta de regalo o una transferencia bancaria. Esa es una evidente señal de estafa.",
      vishingText8:
        "Las personas que llaman de manera auténtica estarán encantadas de trabajar con usted. Las personas que llaman con intenciones ilegales pueden pasar rápidamente a la búsqueda de un objetivo más fácil.",
      smishing: "Smishing",
      smishingIntro:
        "Smishing es un ataque cibernético que utiliza mensajes de texto engañosos. El objetivo es engañar al destinatario para que crea que ha llegado un mensaje de una persona u organización de confianza y luego convencerlo de que tome medidas que le brinden al atacante información explotable (como las credenciales de inicio de sesión de la cuenta bancaria, por ejemplo) o acceso al dispositivo móvil.",
      smishingText1:
        "Intentos de engañarlo para que comparta sus credenciales. Los estafadores pueden intentar convencerlo para que les brinde una combinación de nombre de usuario y contraseña u otra información confidencial que puedan utilizar para entrar en una de sus cuentas en línea. Los engaños bancarios y los engaños fingiendo ser Amazon son muy comunes.",
      smishingText2:
        "Intentos de engañarlo para que descargue malware. Los estafadores envían mensajes imprecisos con enlaces o presionan a los destinatarios para que descarguen aplicaciones. Al igual que el phishing, estos enlaces o descargas podrían tener intenciones maliciosas, como robar información de tarjetas de crédito o brindar acceso a su dispositivo móvil.",
      smishingText3:
        "Desconfíe de los mensajes de texto que utilicen lenguaje poco natural o con errores gramaticales.",
      smishingText4:
        "Las ofertas que parecen demasiado buenas para ser verdad suelen serlo.",
      smishingText5:
        "No haga clic en enlaces embebidos ni descargue aplicaciones directamente desde un mensaje de texto",
      smishingText6:
        "El IRS y la Administración del Seguro Social no se comunican a través de mensajes de texto.",
      recoverAttack: "Cómo recuperarse de un ataque de ingeniería social",
      recoverSummary:
        "La recuperación de un ataque de ingeniería social depende de la naturaleza del ataque y de lo que se haya visto comprometido. Asegúrese de que su antivirus esté actualizado y de cambiar sus contraseñas. Si por error le brindó al atacante alguna información personal, comuníquese con cualquier institución afectada para que lo orienten respecto de cómo proteger sus datos. Si sospecha que se ha puesto en riesgo su información crediticia, comuníquese inmediatamente con las agencias de crédito para congelar su crédito o solicitar orientación sobre los pasos a seguir para la recuperación.",
    },
    rights: {
      bannerTitle: "Ejerza Sus Derechos",
      pageTitle: "Solicitud de Derechos de Privacidad",
      introTitle: "Ley de Privacidad del Consumidor de California (CCPA)",
      introDesc:
        "La CCPA otorga a los residentes de California ciertos derechos de privacidad con respecto a parte de la información personal que recopilamos. Estos derechos son:",
      listText1:
        "El derecho a ser notificado sobre la información personal que recopilamos.",
      listText2:
        "El derecho a saber las categorías, fuentes y partes específicas de la información personal que hemos recopilado sobre usted en los últimos 12 meses, incluyendo nuestro propósito de recopilar la información y las categorías de terceros con los que compartimos esa información personal, sujeto a ciertas excepciones.",
      listText3:
        "El derecho a eliminar alguna o toda la información personal que recopilamos, con ciertas excepciones.",
      listText4:
        "El derecho a optar de no participar en nuestra venta de su información personal, si vendemos su información personal.",
      rightsTitle: "Puede Ejercer Sus Derechos Aquí",
      submitRequestTitle: "Envíe una Solicitud de Datos Personales",
      submitRequestBtn: "Enviar una Solicitud",
      agentRequestText:
        "Si presenta esta solicitud en nombre de otra persona, debe hacerlo por correo postal. Descargue",
      here: "aquí",
      checkStatusTitle: "¿Ya envió una solicitud?",
      checkStatusBtn: "Verificar Estado",
      requestByCall:
        "También puede presentar una solicitud de datos personales llamando a nuestra línea gratuita 1-855-768-9730.",
      requestByMail: "Además, puede enviar una solicitud por correo postal.",
      downloadinstr: "Descargar Instrucciones",
    },
    submitRequestStart: {
      introText:
        "La Ley de Privacidad del Consumidor de California (CCPA) es una legislación de California que permite a los residentes de California acceder o eliminar los datos personales que una empresa recopila sobre ellos. Debido a que la CCPA solo ofrece estas opciones a los residentes de California, Aflac solo cumplirá con las solicitudes presentadas por los residentes de California.",
    },
    optoutRequestStart: {
      introText1:
        "La Ley de Privacidad del Consumidor de California (CCPA) es una legislación de California que permite a los residentes de California optar por no vender su información personal. Debido a que la CCPA solo ofrece esta opción a los residentes de California, Aflac solo cumplirá con las solicitudes presentadas por los residentes de California.",
      introText2:
        "Como recordatorio, Aflac no vende a terceros información personal sobre clientes actuales ni anteriores. Si bien estas no son nuestras prácticas habituales, ofrecemos a nuestros clientes la oportunidad de comunicarnos sus preferencias y registrarlas formalmente.",
      introText3:
        "Seleccione la opción que mejor describa su estado de residencia en California.",
    },
    starterPage: {
      pageTitle: "Inicie el Proceso",
      californiaResident: "Soy Residente de California",
      nonCaliforniaResident: "No Soy Residente de California",
      intro:
        "Antes de iniciar el proceso, asegúrese de contar con lo siguiente:",
      header1:
        "Una dirección de correo electrónico válida a la que pueda acceder.",
      description1:
        "Se le pedirá que verifique su dirección de correo electrónico después de enviar la solicitud. Si es un cliente actual o anterior de Aflac o de las filiales de Aflac, este correo electrónico debe estar asociado a su cuenta. Si tiene varias direcciones de correo electrónico asociadas a una cuenta, por favor envíe una solicitud por separado para cada una de ellas.",
      header2:
        "Una copia digital o una foto de una licencia de conducir o un pasaporte de Estados Unidos en válida.",
      description2:
        "Aviso: Por el momento, solo admitimos verificación mediante licencia de conducir y pasaporte de Estados Unidos. No presente ninguna otra forma de identificación; por ejemplo, una Identificación Militar.",
      description3:
        "Si desea obtener más información sobre cómo Aflac puede recopilar, utilizar y compartir su información personal, por favor visite nuestra",
      privacyPolicy: "Política de Privacidad",
      requestByCall:
        "También puede presentar una solicitud de datos personales llamando a nuestra línea gratuita 1-855-768-9730.",
      requestByMail: "Además, puede enviar una solicitud por correo postal.",
      downloadinstr: "Descargar Instrucciones",
    },
    rejectionPage: {
      pageTitle:
        "La CCPA extiende los derechos de privacidad solo a los residentes de California.",
      introText:
        "Usted ha indicado que no es residente de California. Como institución financiera y compañía de seguros, tenga la seguridad de que todos los datos de los clientes recopilados, procesados, almacenados o divulgados por Aflac están sujetos y protegidos por la Ley Gramm-Leach-Bliley (GLBA), la Ley de Portabilidad y Responsabilidad de los Seguros Médicos (HIPAA) y otras leyes estatales de privacidad y seguridad de datos que correspondan. En Aflac, valoramos su privacidad y consideramos que la protección de su información personal es de suma importancia.",
      policyText:
        "Si desea obtener más información sobre cómo Aflac puede recopilar, utilizar y compartir su información personal, por favor visite nuestra",
      privacyPolicy: "Política de Privacidad",
    },
    submitRequestPage: {
      pageTitle: "Enviar Solicitud",
      pageIntro:
        "Usted puede realizar ciertas solicitudes con respecto a nuestra recopilación, uso y uso compartido de su información personal. Las solicitudes disponibles para usted pueden variar según el estado. Cumpliremos con las solicitudes según lo determine su estado de residencia.",
      stateOfResidence: "Estado de Residencia",
      selectState: "Seleccionar el Estado",
      submit: "Enviar",
      nonCAStatesText1:
        "Gracias por su solicitud. Los derechos de privacidad que están disponibles a través de este proceso no están disponibles en su estado de residencia.",
      nonCAStatesText2:
        "Como institución financiera y compañía de seguros, la información personal que podemos recopilar, procesar, almacenar o divulgar está sujeta a varias leyes federales y estatales de privacidad y protección de datos, incluida la Ley Gramm-Leach-Bliley (GLBA, por sus siglas en inglés) y la Ley de Portabilidad y Responsabilidad de los Seguros Médicos (HIPAA, por sus siglas en inglés).",
      nonCAStatesText3:
        "En Aflac, valoramos su privacidad y consideramos que la protección de su información personal es sumamente importante. Si desea obtener más información sobre cómo Aflac puede recopilar, usar y compartir su información personal, por favor consulte las",
      aflacPrivacyPolicy: "Políticas de Privacidad de Aflac",
      impInfo: "Información Importante",
      infoDesc:
        "Por favor tenga en cuenta que puede haber casos en los que el acceso a su información personal o la eliminación de su información personal se encuentre restringida por la ley federal o estatal. Si se nos prohíbe cumplir con sus derechos de privacidad, en su totalidad o en parte, le informaremos las razones específicas, salvo limitaciones legales o reglamentarias.",
      infoText1:
        "Es posible que sus opciones de privacidad no se apliquen correctamente si ingresa información incorrecta.",
      infoText2:
        "La información personal que proporcione se utilizará SOLO con fines de verificación.",
      noLawStatesText:
        "Gracias por tu solicitud. Los derechos de privacidad que están disponibles a través de este proceso no están disponibles en su estado de residencia.",
      aboutAflacText1:
        "Tenga la seguridad de que todos los datos de los clientes recopilados, procesados, almacenados o divulgados por Aflac están sujetos y protegidos por la Ley Gramm-Leach-Bliley (GLBA), la Ley de Portabilidad y Responsabilidad de Seguros Médicos (HIPAA) y otros estados de privacidad aplicables. y leyes de seguridad de datos.",
      aboutAflacText2:
        "En Aflac, valoramos su privacidad y consideramos que la protección de su información personal es de vital importancia. Si desea obtener más información sobre cómo Aflac puede recopilar, usar y compartir su información personal, consulte el sitio web de Aflac.",
      privacyPolicy: "Política de Privacidad",
      moreInfo: "para obtener más información.",
      virginiaAct: "Virginia Data Protection Act (VCDPA)",
      coloradoAct: "Colorado Privacy Act (CPA)",
      utahAct: "Utah Consumer Privacy Act (UCPA)",
      connecticutAct: "Connecticut Data Privacy Act (CDPA)",
      nonComplyStatesText:
        "Como institución financiera y compañía de seguros, Aflac está exenta del",
      review: "Por favor revise nuestra",
      reviewText:
        "para obtener detalles completos sobre cómo Aflac, Inc. gestiona la información del consumidor.",
      infoText3:
        "Antes de comenzar el proceso, por favor asegúrese de que tiene:",
      infoText4:
        "(a) Una dirección de correo electrónico válida a la que pueda acceder. Se le pedirá que verifique la dirección de su correo electrónico después de enviar la solicitud.",
      infoText5:
        "(b) Una copia digital o una foto de una licencia de conducir o pasaporte válido de los Estados Unidos.",
      requestByMail:
        "Si presenta esta solicitud en nombre de otra persona, debe hacerlo por correo postal. Descargue las instrucciones de envío por correo postal en virtud de la CCPA",
      here: "aquí",
      email: "Ingrese detalles adicionales para su solicitud.",
      emailPlaceholder: "Dirección de Correo Electrónico *",
      emailText:
        "Usaremos esta dirección de correo electrónico para responder a su solicitud.",
      relationshipToAflac: "¿Cuál es su relación actual o anterior con Aflac?",
      customer:
        "Cliente (Titular de Póliza, Titular de Certificado, Cónyuge o Dependiente)",
      employee: "Empleado",
      jobApplicant: "Solicitante de Empleo",
      salesBroker: "Agente de Ventas o Corredor",
      contractor: "Contratista",
      access: "Acceso",
      accessDescription:
        "Una solicitud para que Aflac divulgue cierta información personal que haya recopilado sobre usted.",
      delete: "Eliminar",
      deleteDescription:
        "Una solicitud para que Aflac elimine la información personal que haya recopilado y retenido sobre usted, sujeto a ciertas excepciones.",
      optOut: "Exclusión Voluntaria para Vender o Compartir",
      optOutDescription:
        "Una solicitud para que Aflac registre su preferencia de no compartir su información personal con terceros o usar su información personal para cierta publicidad conductual.",
      correction: "Corrección",
      correctionDescription:
        "Una solicitud para que Aflac corrija la información personal incorrecta que mantiene sobre usted.",
      optOutDataProcessing: "Limitar la Información Personal Confidencial",
      optOutDataProcessingDescription:
        "Una solicitud para que Aflac registre su preferencia de limitar el uso y la divulgación de cierta información personal confidencial sobre usted para el desempeño de ciertos servicios.",
      specifyUpdateReq:
        "Por favor, especifique lo que necesita ser actualizado / corregido.",
      specifyUpdateReqPlaceholder:
        "Por favor, no proporcione ninguna información personal confidencial a través de este formulario.",
      recentStatus:
        "Seleccione la compañía a través de la cual ha solicitado o se le ha emitido la cobertura.",
      personType:
        "¿Es usted la persona principal que solicitó la cobertura o el Cónyuge / Dependiente?",
      requestType: "¿Qué tipo de solicitud le gustaría hacer?",
      aflacIndividual: "Aflac Individual",
      aflacGroup: "Aflac Group",
      notSure: "No Estoy Seguro",
      both: "Ambas",
      neither1: "Ninguna",
      primary: "Principal",
      spouse: "Cónyuge",
      dependent: "Dependiente",
      neither2: "Ninguno",
      spouseDepPlaceholderFN: "Nombre del Cónyuge / Dependiente *",
      spouseDepPlaceholderLN: "Apellido del Cónyuge / Dependiente *",
      eNumber:
        "Por favor, proporcione su E-Number o los últimos 4 dígitos de su Número de Seguro Social (NSS).",
      ssnDigits: "Últimos 4 Dígitos del NSS",
      writingNumberLabel:
        "Por favor, proporcione su Número de Agente o los últimos 4 dígitos de su Número de Seguro Social (NSS) o Número de Identificación del Contribuyente (TIN).",
      writingNumber: "Número de Agente",
      ssntnDigits: "Últimos 4 Dígitos del NSS / TIN",
      vNumber: "Por favor, proporcione su V-Number.",
      accessInfo: "Acceder a mi información personal",
      deleteInfo: "Eliminar mi información personal",
      enterpriInsuredInfo:
        "Por favor introduzca la información requerida correspondiente al Asegurado Principal.",
      firstName: "Nombre *",
      lastName: "Apellido *",
      address1: "Dirección Línea 1 *",
      address2: "Dirección Línea 2",
      city: "Ciudad *",
      state: "Estado *",
      zipCode: "Código Postal *",
      country: "País *",
      dob: "Fecha de Nacimiento *",
      required: "* Los campos marcados con asterisco son obligatorios.",
      submitBtn: "Enviar",
      cancelBtn: "Cancelar",
      ackTitle: "Confirmación",
      cardTitle: "Revise su Bandeja de Entrada",
      cardText1:
        "Le hemos enviado un enlace para que continúe con su solicitud. Diríjase a su bandeja de entrada y busque un correo electrónico de",
      cardText1OptOuts:
        "Diríjase a su bandeja de entrada y busque un correo electrónico de",
      cardText2:
        "Si no recibe el correo electrónico en las próximas horas, por favor compruebe sus filtros de CORREO NO DESEADO o vuelva a enviar su solicitud.",
      requestID: "Identificación de Su Solicitud:",
      save: "Guárdela para sus archivos.",
      cardText3:
        "El correo electrónico le pedirá que confirme su solicitud. También es posible que deba verificar su identidad cargando una copia de su licencia de conducir o pasaporte junto con una foto actual de usted. Debe enviar su identificación gubernamental y foto actual dentro de los 60 minutos posteriores a la recepción del correo electrónico, o tendrá que volver a enviar su solicitud.",
    },
    optoutRequestPage: {
      pageTitle: "Solicitud de Exclusión",
      pageIntro:
        "En esta página, puede optar por ejercer su derecho de exclusión como residente de California.",
      impInfo: "Información Importante",
      infoDesc:
        "Si no podemos procesar su solicitud de exclusión, le informaremos la razón específica, salvo limitaciones legales o reglamentarias.",
      infoText1:
        "Es posible que sus opciones de privacidad no se apliquen correctamente si ingresa información incorrecta.",
      infoText2:
        "La información personal que proporcione se utilizará SOLO con fines de verificación.",
      review: "Por favor revise nuestra",
      privacyPolicy: "Política de Privacidad",
      reviewText:
        "para obtener detalles completos sobre cómo Aflac, Inc. gestiona la información del consumidor.",
      email: "Correo electrónico *",
      emailPlaceholder: "Ingrese su dirección de correo electrónico *",
      emailText:
        "Usaremos esta dirección de correo electrónico para responder a su solicitud",
      enterpriInsuredInfo:
        "Por favor introduzca la información requerida correspondiente al Asegurado Principal.",
      firstName: "Nombre *",
      lastName: "Apellido *",
      address1: "Dirección Línea 1 *",
      address2: "Dirección Línea 2",
      city: "Ciudad *",
      state: "Estado *",
      zipCode: "Código postal *",
      country: "País *",
      dob: "Fecha de Nacimiento *",
      required: "* Los campos marcados con asterisco son obligatorios.",
      submitBtn: "Enviar",
      cancelBtn: "Cancelar",
      ackTitle: "Confirmación",
      cardTitle: "Revise su Bandeja de Entrada",
      cardText1:
        "Le hemos enviado un enlace para que continúe con su solicitud. Diríjase a su bandeja de entrada y busque un correo electrónico de",
      cardText2:
        "Si no recibe el correo electrónico en las próximas horas, por favor  compruebe sus filtros de CORREO NO DESEADO o vuelva a enviar su solicitud.",
      requestID: "Identificación de Su Solicitud:",
      save: "Guárdela para sus archivos.",
      cardText3: "El correo electrónico le pedirá que confirme su solicitud.",
    },
    pdfPage: {
      back: "atrás",
      download: "Descargar PDF",
      instructions:
        "Instrucciones para el envío por correo postal a Aflac en virtud de la CCPA",
      header1:
        "Los derechos de privacidad que están disponibles a través de este proceso solo están disponibles para los residentes del estado de California.",
      header2: "Para enviar y recibir su solicitud por correo",
      listText1:
        "Prueba de Identidad (copia del anverso y reverso de una Prueba de Identidad Aceptable)",
      listItem1:
        "Prueba de Identidad Aceptable es un Documento de Identificación / Licencia de Conducir Emitido por un Estado o un Pasaporte o una Tarjeta de Pasaporte.",
      listText2:
        "El tipo de solicitud que se presenta y proporcione información de contacto, incluidos el nombre, el número de teléfono, la dirección y si usted es residente de California o no.",
      listText3:
        "Permita hasta 45 días para que Aflac procese la solicitud. Esto puede extenderse otros 45 días si se necesita más tiempo. Si se produce una extensión, Aflac se lo informará en el plazo inicial de 45 días.",
      listText4: "Envíe la solicitud a:",
      listItem2:
        "Para Aflac: Aflac, Attn: Privacy Office, 1932 Wynnton Road, Columbus, GA 31999.",
      listItem3:
        "Para Aflac Group / CAIC: Aflac Group, Attn: Privacy Office, PO Box 427, Columbia, SC 29202.",
      header3:
        "Si presentará esta solicitud en nombre de otra persona, además de lo anterior, por favor incluya también lo siguiente:",
      listText5:
        "Poder Notarial u otro documento oficial que lo autorice a actuar en nombre del Titular de los Datos.",
      listText6:
        "Prueba de Identidad del tercero que efectúa la solicitud (copia del anverso y reverso de una Prueba de Identidad Aceptable).",
      listItem4:
        "Prueba de Identidad Aceptable es un Documento de Identificación / Licencia de Conducir Emitido por un Estado o un Pasaporte o una Tarjeta de Pasaporte.",
      listText7:
        "Copia del comprobante de inscripción en la Secretaría de Estado (si representa a una entidad comercial).",
      listText8:
        "Información de contacto, incluidos su nombre, número de teléfono y dirección a la cual enviar comunicaciones.",
    },
  },
};
